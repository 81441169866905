import { useState, useEffect, useCallback, FC, SyntheticEvent } from 'react';
import { useTranslation } from 'react-i18next';
import moment from 'moment';

import { useLoading } from './context/loading-context';
import { useErrorHandler } from './context/error-handler-context';
import axios from './utils/axios';

import {
  ProductsArr,
  ProductPeriod,
  InstallmentPeriod,
  getProductCatalogue,
} from './models/products';
import { getUtmFromStorage } from './utils/utm';

import Snackbar from '@mui/material/Snackbar';
import Slider from './components/Sections/Offer/Slider';

import Loading from './components/UI/Loading';
import styles from './components/Pages/Offer.module.scss';
import Alert from './components/UI/Alert';
import { parseErrorMessage } from './utils/utils';
import { Container, Button, Typography, Stack } from '@mui/material';
import PaymentsDetails from './components/Sections/Offer/PaymentsDetails';
import CurrencySwitcher from './components/Sections/Offer/CurrencySwitcher';
import { IsoCurrencyCode } from './types/api';

function IndicativeOffer() {
  const { t } = useTranslation();

  const [aktProdukt, setAktProdukt] = useState(
    getProductCatalogue()[ProductsArr[ProductsArr.length - 1].code]
  );

  const [postponeLength, setPostponeLength] = useState<number>(0);
  const [tglCrowdfunding, setTglCrowdfunding] = useState<boolean>(false);
  const [tglProlongation, setTglProlongation] = useState<boolean>(false);
  const [financingValue, setFinancingValue] = useState<number>(0);
  const [minValue, setMinValue] = useState<number>(50000);
  const [maxValue, setMaxValue] = useState<number>(0);
  const [feeMonthly, setFeeMonthly] = useState<number>(0);
  const [amountComplete, setAmountComplete] = useState<number>(0);
  const [currency, setCurrency] = useState<IsoCurrencyCode>(
    IsoCurrencyCode.CZK
  );

  const { loading, setLoading } = useLoading();
  const { error, setError } = useErrorHandler();

  const updateFinancingValue = (newValue: number) => {
    if (newValue < minValue || newValue > maxValue) {
      return;
    }

    setFinancingValue(newValue);
  };

  const handleCurrencyChange = (
    event: React.MouseEvent<HTMLElement>,
    newCurrency: IsoCurrencyCode
  ) => {
    setCurrency(newCurrency);
    setMinValue(newCurrency === IsoCurrencyCode.CZK ? 50000 : 2000);
    setMaxValue(newCurrency === IsoCurrencyCode.CZK ? 1000000 : 40000);
  };

  const calculateInstallmentPlan = useCallback(
    (
      aktProdukt: Record<string, any>,
      loanAmount: number,
      postponeLength: number,
      fromDate: Date | string,
      crowdfunding = false,
      prolongation = false,
      fee?: number
    ) => {
      const round2 = (num: number) => {
        var m = Number((Math.abs(num) * 100).toPrecision(15));
        return (Math.round(m) / 100) * Math.sign(num);
      };

      const productDef = aktProdukt;

      let celkemSplatky = 0;
      let celkemKomplet = 0;

      let firstDate = moment.utc(fromDate);
      firstDate.add(3, 'days');

      if (!productDef.postponeEnabled) {
        postponeLength = 0;
      }

      let prolongationLength =
        productDef.prolongationEnabled && (prolongation || crowdfunding)
          ? 1
          : 0;

      const installmentCount = productDef.installmentCount + prolongationLength;
      const installmentAmount = Math.ceil(
        loanAmount / (productDef.installmentCount - postponeLength)
      );

      const feeMonthly =
        fee === undefined
          ? Math.ceil(
              round2(
                round2((productDef.anonymousOfferRatePerc / 100) * loanAmount) *
                  productDef.interestRateMultiplier
              )
            )
          : Math.ceil(
              round2(
                round2((fee / 100) * loanAmount) *
                  productDef.interestRateMultiplier
              )
            );

      const postponeFeeMultiplier =
        postponeLength > 0
          ? crowdfunding
            ? productDef.postponeFeeMultiplierCF
            : productDef.postponeFeeMultiplier
          : 1;
      const postponeFee =
        postponeLength > 0 ? postponeFeeMultiplier * feeMonthly : 0;

      const prolongationFeeMultiplier =
        prolongationLength > 0
          ? crowdfunding
            ? productDef.prolongationFeeMultiplierCF
            : productDef.prolongationFeeMultiplier
          : 1;
      const prolongationFee =
        prolongationLength > 0 ? prolongationFeeMultiplier * feeMonthly : 0;

      let nextDate = moment(); //to ensure it is not undefined
      let listSplatek = [];

      for (let i = 0; i < installmentCount; i++) {
        if (productDef.installmentPeriod === InstallmentPeriod.Month) {
          nextDate = moment.utc(firstDate);
          nextDate.add(i + 1, 'month');
        } else {
          nextDate.add(7 * productDef.installmentPeriodCount, 'days');
        }

        const isLast = i + 1 === installmentCount;
        const isPostponed = postponeLength > 0 && i < postponeLength;
        const isProlonged = prolongationLength > 0 && i < prolongationLength;
        const amount =
          isPostponed || isProlonged || (productDef.baloon && !isLast)
            ? 0
            : isLast
            ? loanAmount - celkemSplatky
            : installmentAmount;
        const fee =
          feeMonthly +
          (isPostponed ? postponeFee : 0) +
          (isProlonged ? prolongationFee : 0);

        celkemSplatky = celkemSplatky + amount;
        celkemKomplet = celkemKomplet + amount + fee;

        listSplatek.push({
          index: i + 1,
          principal: amount,
          fee: fee,
          dueDate: nextDate?.toISOString().slice(0, 10),
        });
      }

      const postponeFeeDef =
        productDef.postponeEnabled && postponeLength > 0
          ? productDef.postponeFeeMultiplier * feeMonthly * postponeLength
          : 0;
      const prolongationFeeDef = productDef.prolongationEnabled
        ? productDef.prolongationFeeMultiplier * feeMonthly
        : 0;

      return {
        product: aktProdukt,
        productId: productDef.id,
        baloon: productDef.baloon,
        crowdfunding: crowdfunding,

        amount: loanAmount,
        feeMonthly: feeMonthly,
        amountComplete: celkemKomplet,
        amountCompleteBrutto:
          celkemKomplet +
          (crowdfunding ? postponeFeeDef + prolongationFeeDef : 0),

        postponeLength: postponeLength,
        feePostponeMonth:
          productDef.postponeEnabled && postponeLength > 0
            ? postponeFeeMultiplier * feeMonthly
            : 0,
        feePostponeTotal:
          productDef.postponeEnabled && postponeLength > 0
            ? postponeFeeMultiplier * feeMonthly * postponeLength
            : 0,
        feePostpone: postponeFeeDef,

        prolongation: prolongation,
        prolongationLength: prolongationLength,
        feeProlongation: prolongationFeeDef,
        feeProlongationTotal: prolongationFee,

        installments: listSplatek,
      };
    },
    []
  );

  const handleOfferInterested = async () => {
    const utm = getUtmFromStorage();

    let offerData = {
      productParams: {
        type: aktProdukt.id,
        amount: financingValue,
        postponedPeriods: postponeLength,
        prolonged: tglProlongation,
        crowdfunded: false,
      },
    };

    if (utm) {
      offerData = {
        ...offerData,
        ...utm,
      };
    }

    //@ts-ignore
    axios.defaults.skipAuthToken = true;
    axios
      .post(`leads/indicative-interest`, offerData)
      .then((res) => {
        setLoading(false);

        if (res.data) {
          window.open(`/signup/lead/${res.data.id}`, '_top');
        }
      })
      .catch((err) => {
        setLoading(false);
        setError(err);
      });
  };

  const handleCloseErrorAlert = (
    event: SyntheticEvent<Element, Event> | Event,
    reason?: string
  ) => {
    if (reason === 'clickaway') {
      return;
    }

    setError(null);
  };

  useEffect(() => {
    if (!aktProdukt) {
      return;
    }

    setMaxValue(aktProdukt.anonymousOfferMaxAmount);
    setFinancingValue(aktProdukt.anonymousOfferMaxAmount);
    setTglProlongation(false);
    setTglCrowdfunding(false);
    setPostponeLength(aktProdukt.typProduktu === ProductPeriod.M3 ? 2 : 0);
  }, [aktProdukt]);

  useEffect(() => {
    if (!financingValue || !aktProdukt) {
      return;
    }

    const plan = calculateInstallmentPlan(
      aktProdukt,
      financingValue,
      postponeLength,
      new Date().toDateString(),
      tglCrowdfunding,
      tglProlongation
    );

    setFeeMonthly(plan.feeMonthly);
    setAmountComplete(plan.amountComplete);
  }, [
    calculateInstallmentPlan,
    financingValue,
    postponeLength,
    aktProdukt,
    tglCrowdfunding,
    tglProlongation,
  ]);

  useEffect(() => {
    setAktProdukt(
      getProductCatalogue(currency)[ProductsArr[ProductsArr.length - 1].code]
    );
  }, [currency]);

  let content = (
    <Container maxWidth="xs">
      <div className={`${styles['offer-wrapper']} ${styles['indicative']}`}>
        <CurrencySwitcher
          currency={currency}
          handleCurrencyChange={handleCurrencyChange}
          isIndicativeOffer
        />

        <div className={styles.slider}>
          <Slider
            value={financingValue}
            minValue={minValue}
            maxValue={maxValue}
            updateValue={updateFinancingValue}
            currency={currency}
            isIndicative
          />
        </div>

        <h2 className={`${styles['how-long']} ${styles.indicative}`}>
          {t('offer-how-long')}
        </h2>
        <div className={`${styles['products-wrapper']} ${styles.indicative}`}>
          {ProductsArr.filter((p) => {
            return p.code !== 'M3R';
          }).map((p) => {
            const productDef = getProductCatalogue(currency)[p.code];
            const isAvailable = true; // all available for now

            return (
              <button
                key={p.code}
                className={`${isAvailable && styles.offerd} ${
                  aktProdukt.id === p.code && styles.selected
                }`}
                onClick={
                  isAvailable ? () => setAktProdukt(productDef) : undefined
                }
              >
                {productDef.text}
              </button>
            );
          })}
        </div>

        <PaymentsDetails
          financingValue={financingValue}
          feeMonthly={feeMonthly}
          amountComplete={amountComplete}
          currency={currency}
          isIndicativeOffer
        />

        <Stack justifyContent="center" alignItems="center">
          <Button
            onClick={handleOfferInterested}
            variant="contained"
            color="secondary"
          >
            <Typography color="white" variant="h6">
              {t('offer-interested-alt')}
            </Typography>
          </Button>
        </Stack>
      </div>
    </Container>
  );

  return (
    <Container maxWidth="sm">
      <div className="content-wrapper">
        <Loading isLoading={loading} />
        <Snackbar
          open={Boolean(error)}
          autoHideDuration={5000}
          onClose={handleCloseErrorAlert}
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        >
          <Alert
            onClose={handleCloseErrorAlert}
            severity="error"
            sx={{ width: '100%' }}
          >
            {parseErrorMessage(error)}
          </Alert>
        </Snackbar>
        {content}
      </div>
    </Container>
  );
}

export default IndicativeOffer;
